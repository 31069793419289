










































































































































































































































































































































































































































































































































































































































































































.showtitle {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: relative;
  top: 100%;
  left: 0;
}

/deep/.el-textarea .el-input__count {
  line-height: 20px;
}

.czxz {
  color: #fe3131;
  font-size: 14px;
  position: absolute;
  right: 10px;
  cursor: pointer;
  .popover_1 {
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    color: #409EFF;
  }
  .popover_2 {
    span {
      color: #409EFF;
    }
  }
  .popover_3 {
    color: #fe3131;
  }
}
